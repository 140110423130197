<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label=事件类型 prop="order_type" style="width: 25%">
              <a-select v-model="queryParams.order_type">
                <a-select-option value="0">报事</a-select-option>
                <a-select-option value="1">报修</a-select-option>
                <a-select-option value="3">转计划维修</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="事件来源" prop="order_source" style="width: 25%">
              <a-select v-model="queryParams.order_source">
                <a-select-option value="自报">自报</a-select-option>
                <a-select-option value="调度中心">调度中心</a-select-option>
                <a-select-option value="外部报">外部报</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="报事/报修类型" prop="repair_matter_type" style="width: 25%">
                <a-select v-model="queryParams.repair_matter_type">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option v-if="queryParams.order_type==='0'" value="建议">建议</a-select-option>
                  <a-select-option v-if="queryParams.order_type==='0'" value="问询">问询</a-select-option>
                  <a-select-option v-if="queryParams.order_type==='0'" value="质疑">质疑</a-select-option>
                  <a-select-option v-if="queryParams.order_type==='0'" value="求助">求助</a-select-option>
                  <a-select-option v-if="queryParams.order_type==='1'" value="公区维修">公区维修</a-select-option>
                  <a-select-option v-if="queryParams.order_type==='1'" value="专属维修">专属维修</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="重大影响" prop="biginfluence" style="width: 25%">
                <a-select v-model="queryParams.biginfluence">
                  <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="处理形式" prop="handle_form" style="width: 25%">
                <a-select v-model="queryParams.handle_form">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="自理">自理</a-select-option>
                  <a-select-option value="自修">自修</a-select-option>
                  <a-select-option value="外委">外委</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item v-if="queryParams.order_type==='1'" label="维修原因" prop="fault" style="width: 25%">
                <a-select v-model="queryParams.fault">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="误报">误报</a-select-option>
                  <a-select-option value="紧固">紧固</a-select-option>
                  <a-select-option value="润滑">润滑</a-select-option>
                  <a-select-option value="调整">调整</a-select-option>
                  <a-select-option value="损坏">损坏</a-select-option>
                  <a-select-option value="其它">其它</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item v-if="queryParams.order_type==='1'" label="原因分类" prop="fault_classification" style="width: 25%">
                <a-select v-model="queryParams.fault_classification">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="自然损坏">自然损坏</a-select-option>
                  <a-select-option value="人为损坏">人为损坏</a-select-option>
                  <a-select-option value="保养不当">保养不当</a-select-option>
                  <a-select-option value="灾害损坏">灾害损坏</a-select-option>
                  <a-select-option value="产品质量">产品质量</a-select-option>
                  <a-select-option value="设计缺陷">设计缺陷</a-select-option>
                  <a-select-option value="其它原因">其它原因</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="响应率" prop="response_rate" style="width: 25%">
                <a-select v-model="queryParams.response_rate">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="小于5分钟">小于5分钟</a-select-option>
                  <a-select-option value="小于15分钟">小于15分钟</a-select-option>
                  <a-select-option value="小于30分钟">小于30分钟</a-select-option>
                  <a-select-option value="小于1小时">小于1小时</a-select-option>
                  <a-select-option value="大于等于1小时">大于等于1小时</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="执行率" prop="implementation_rate" style="width: 25%">
                <a-select v-model="queryParams.implementation_rate">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="小于1小时">小于1小时</a-select-option>
                  <a-select-option value="小于2小时">小于2小时</a-select-option>
                  <a-select-option value="小于4小时">小于4小时</a-select-option>
                  <a-select-option value="小于24小时">小于24小时</a-select-option>
                  <a-select-option value="大于等于24小时">大于等于24小时</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="完成率" prop="completion_rate" style="width: 25%">
                <a-select v-model="queryParams.completion_rate">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="小于24小时">小于24小时</a-select-option>
                  <a-select-option value="小于48小时">小于48小时</a-select-option>
                  <a-select-option value="小于72小时">小于72小时</a-select-option>
                  <a-select-option value="小于168小时">小于168小时</a-select-option>
                  <a-select-option value="大于等于168小时">大于等于168小时</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="时段" prop="queryInstallDate" style="width: 25%">
                <a-range-picker v-model="queryInstallDate"></a-range-picker>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}<h5 style="color: red">说明：响应时间=接单时间-接报时间；执行时间=签到时间-接报时间；完成时间=结单时间-接报时间</h5></h3>
          <div class="table-btns">
<!--            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="order_type" slot-scope="value">
            <span :title="value==='3'?'转计划维修':value==='1'?'报修':value==='0'?'报事':''">{{value==='3'?'转计划维修':value==='1'?'报修':value==='0'?'报事':''}}</span>
          </span>
          <span slot="order_number" slot-scope="value,record">
            <a-button type="link" @click="openRepairAndMatter(record,'repairAndMatter')">{{value}}</a-button>
          </span>
          <span slot="biginfluence" slot-scope="value">
            <span>{{value?bigInfluences[value-1].dicvalue:''}}</span>
          </span>
          <span slot="min_finish_time" slot-scope="value,record">
            <a-button type="link" @click="openRepairAndMatter(record,'minOrder')">{{timeHandle(value)}}</a-button>
          </span>
          <span slot="max_finish_time" slot-scope="value,record">
            <a-button type="link" @click="openRepairAndMatter(record,'maxOrder')">{{timeHandle(value)}}</a-button>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.order_id">详情</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, measure_range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <repair-and-matter-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData"></repair-and-matter-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  exportRepairsOrderInfoExcel,
  getRepairsOrderAnalysisByCondition,
} from "A/repairsreport";
import repairAndMatterModal from "C/modals/RepairAndMatterModal.vue";
import addOrEditModal from "V/matterAndRepairs/repair/addOrEditModal.vue";
export default {
  name: "repairAndMatterReport",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
    repairAndMatterModal,
  },
  data() {
    return {
      moment,
      userdepidCascaderSelected: [],
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      bigInfluences:[],
      monitorpointList:[],
      queryParams:{
        monitorpointnum: '',
        monitorpointname: '',
        order_type: '',
        order_source: '',
        order_num: '',
        repair_matter_type: '',
        is_postpone: '',
        order_status: '',
        contacts: '',
        contact_information: '',
        process_node: '',
        starttime: '',
        endtime: '',
        response_rate: '',
        implementation_rate: '',
        completion_rate: '',
        fault: '',
        fault_classification: '',
        handle_form: '',
        biginfluence: '',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          scopedSlots: { customRender: 'monitorpointname' },
        },
        {
          title: '报事报修数',
          dataIndex: 'order_number',
          key: 'order_number',
          align: "center",
          ellipsis: true,
          scopedSlots: { customRender: 'order_number' },
        },
        // {
        //   title: '事件类型',
        //   dataIndex: 'order_type',
        //   key: 'order_type',
        //   ellipsis: true,
        //   align:'center',
        //   scopedSlots: { customRender: 'order_type' },
        // },
        // {
        //   title: '事件来源',
        //   dataIndex: 'order_source',
        //   key: 'order_source',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'order_source' },
        // },
        // {
        //   title: '报事报修类型',
        //   dataIndex: 'repair_matter_type',
        //   key: 'repair_matter_type',
        //   ellipsis: true,
        // },
        // {
        //   title: '重大影响',
        //   dataIndex: 'biginfluence',
        //   key: 'biginfluence',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'biginfluence' },
        // },
        // {
        //   title: '处理形式',
        //   dataIndex: 'handle_form',
        //   key: 'handle_form',
        //   ellipsis: true,
        // },
        // {
        //   title: '处理单位',
        //   dataIndex: 'handle_unit',
        //   key: 'handle_unit',
        //   ellipsis: true,
        // },
        // {
        //   title: '维修原因',
        //   dataIndex: 'fault',
        //   key: 'fault',
        //   ellipsis: true,
        // },
        // {
        //   title: '原因分类',
        //   dataIndex: 'fault_classification',
        //   key: 'fault_classification',
        //   ellipsis: true,
        // },
        {
          title: '响应率',
          dataIndex: 'response_rate',
          key: 'response_rate',
          ellipsis: true,
          scopedSlots: { customRender: 'response_rate' },
        },
        {
          title: '执行率',
          dataIndex: 'implementation_rate',
          key: 'implementation_rate',
          ellipsis: true,
          scopedSlots: { customRender: 'implementation_rate' },
        },
        {
          title: '完成率',
          dataIndex: 'completion_rate',
          key: 'completion_rate',
          ellipsis: true,
          scopedSlots: { customRender: 'completion_rate' },
        },
        {
          title: '最短完成时间',
          dataIndex: 'min_finish_time',
          key: 'min_finish_time',
          ellipsis: true,
          scopedSlots: { customRender: 'min_finish_time' },
        },
        {
          title: '最长完成时间',
          dataIndex: 'max_finish_time',
          key: 'max_finish_time',
          ellipsis: true,
          scopedSlots: { customRender: 'max_finish_time' },
        },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   key: 'operation',
        //   ellipsis: true,
        //   align:'center',
        //   scopedSlots: { customRender: 'operation' },
        //   width: 70
        // },
      ],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "报事报修处理分析报表"
    },
    breadcrumb() {
      const pages = [{name:"数据分析与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
    }
    this.getTableData()
    this.initDeptOptionsAll();
    this.getMonitorPointNameList();
  },
  methods:{
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getRepairsOrderAnalysisByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let order_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'order_id', order_id);
      if(type == 'delete') {
        this.deleteConfirm(order_id, record)
      }else if(type == 'detail') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      }
    },
    openRepairAndMatter(record,type){
      this.modalDetailData = record
      this.modalDetailData.order_type=this.queryParams.order_type
      this.modalDetailData.order_source=this.queryParams.order_source
      this.modalDetailData.order_num=this.queryParams.order_num
      this.modalDetailData.repair_matter_type=this.queryParams.repair_matter_type
      this.modalDetailData.is_postpone=this.queryParams.is_postpone
      this.modalDetailData.order_status=this.queryParams.order_status
      this.modalDetailData.contacts=this.queryParams.contacts
      this.modalDetailData.contact_information=this.queryParams.contact_information
      this.modalDetailData.process_node=this.queryParams.process_node
      this.modalDetailData.starttime=this.queryParams.starttime
      this.modalDetailData.endtime=this.queryParams.endtime
      this.modalDetailData.response_rate=this.queryParams.response_rate
      this.modalDetailData.implementation_rate=this.queryParams.implementation_rate
      this.modalDetailData.completion_rate=this.queryParams.completion_rate
      this.modalDetailData.fault=this.queryParams.fault
      this.modalDetailData.fault_classification=this.queryParams.fault_classification
      this.modalDetailData.handle_form=this.queryParams.handle_form
      this.modalDetailData.biginfluence=this.queryParams.biginfluence
      this.modalShowType = type
      this.modalVisible = true
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportRepairAndMatter()
      }).catch(()=>{
      });
    },
    exportRepairAndMatter(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportRepairsOrderInfoExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    timeHandle(time){
      let returnTime;
      let day;
      let hour;
      let minutes;
      let seconds;
      if(time>86400) {
        day = Math.floor(time / 86400);
        returnTime=day + "天";
        if (time % 86400 > 3600) {
          hour = Math.floor(time % 86400 / 3600);
          returnTime+=hour + "时";
          if (time % 86400 % 3600 > 60) {
            minutes = Math.floor(time % 86400 % 3600 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          }
        } else {
          if (time % 86400 > 60) {
            minutes = Math.floor(time % 86400 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400);
            returnTime+=seconds + "秒";
          }
        }
      }else if(time>=3600&&time<86400){
        let hour = Math.floor(time % 86400 / 3600);
        returnTime=hour + "时";
        if (time % 3600 > 60) {
          minutes = Math.floor(time % 3600 / 60);
          returnTime+=minutes + "分";
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        } else {
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        }
      }else if(time>=60&&time<3600){
        minutes = Math.floor(time / 60);
        returnTime=minutes + "分";
        seconds = Math.floor(time % 60);
        returnTime+=seconds + "秒";
      }else if(time){
        returnTime=time + "秒";
      }else{
        returnTime = ""
      }
      return returnTime
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
}
</script>
<style scoped>

</style>