<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :footer="false" :width="1280" :centered="true">
    <div class="menu-modal-container">
      <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
        <span slot="need_finish_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
        </span>
        <span slot="process_node" slot-scope="value">
            <a-tag :color="repairOrderStepColor[value]||''">{{repairOrderSteps[value]||''}}</a-tag>
        </span>
        <span slot="time" slot-scope="value">
          <span>{{timeHandle(value)}}</span>
          </span>
        <span slot="order_type" slot-scope="value">
            <span :title="value===0?'报事':'报修'">{{value===0?'报事':'报修'}}</span>
        </span>
        <span slot="order_status" slot-scope="value">
            <span :title="orderStatus[value]">{{orderStatus[value]}}</span>
          </span>
        <span slot="order_exception" slot-scope="value">
            <a-tag :color="orderExceptionColors[value]||''">{{orderException[value]||''}}</a-tag>
          </span>
        <span slot="biginfluence" slot-scope="value">
            <span>{{value?bigInfluences[value-1].dicvalue:''}}</span>
        </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
    <matter-detail-modal :visible.sync="matterDetailModalVisible" :detail-data="matterDetailModalDetailData"></matter-detail-modal>
    <repair-detail-modal :visible.sync="repairDetailModalVisible" :detail-data="repairDetailModalDetailData"></repair-detail-modal>
  </a-modal>
</template>
<script>
import pagination from "@/mixins/pagination";
import {mapGetters, mapState} from "vuex";
import moment from "moment";
import {getCache} from "U/index";
import {orderStatus, repairOrderStepColor, repairOrderStepList, repairOrderSteps} from "@/json/repairsOrderSteps";
import {getExclusiveRepair, getMinOrMaxRepairsOrder, getRepairsOrderDetailListByCondition} from "A/repairsreport";
import deptselect from "@/mixins/deptselect";
import repairDetailModal from "V/matterAndRepairs/repair/DetailModal.vue";
import matterDetailModal from "V/matterAndRepairs/matter/DetailModal.vue";
import {orderException, orderExceptionColors} from "@/json/maintenance";

export default {
  name: "RepairAndMatterModal",
  mixins: [deptselect,pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: ''
    },
    detailData: {
      default: null
    },
  },
  components: {
    repairDetailModal,
    matterDetailModal
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      bigInfluences:[],
      orderStatus,
      repairOrderStepList,
      repairOrderSteps,
      repairOrderStepColor,
      orderException,
      orderExceptionColors,
      userdepidCascaderSelected: [],
      queryInstallDate:null,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      queryParams: {
        monitorpointnum: '',
        monitorpointname: '',
        order_type: '',
        order_source: '',
        order_num: '',
        repair_matter_type: '',
        is_postpone: '',
        order_status: '',
        contacts: '',
        contact_information: '',
        process_node: '',
        starttime: '',
        endtime: '',
        response_rate: '',
        implementation_rate: '',
        completion_rate: '',
        fault: '',
        fault_classification: '',
        handle_form: '',
        biginfluence: '',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '工单类型',
          dataIndex: 'order_type',
          key: 'order_type',
          ellipsis: true,
          scopedSlots: { customRender: 'order_type' },
        },
        {
          title: '报事/报修单号',
          dataIndex: 'order_num',
          key: 'order_num',
          ellipsis: true,
        },
        {
          title: '报事/报修来源',
          dataIndex: 'order_source',
          key: 'order_source',
          ellipsis: true,
        },
        {
          title: '报事/报修类型',
          dataIndex: 'repair_matter_type',
          key: 'repair_matter_type',
          ellipsis: true,
        },
        {
          title: '设备名称',
          dataIndex: 'device_name',
          key: 'device_name',
          ellipsis: true,
        },
        {
          title: '联系人',
          dataIndex: 'contacts',
          key: 'contacts',
          ellipsis: true,
        },
        {
          title: '联系方式',
          dataIndex: 'contact_information',
          key: 'contact_information',
          ellipsis: true,
        },
        {
          title: '接报内容',
          dataIndex: 'content',
          key: 'content',
          ellipsis: true,
        },
        {
          title: '重大影响',
          dataIndex: 'biginfluence',
          key: 'biginfluence',
          ellipsis: true,
          scopedSlots: { customRender: 'biginfluence' },
        },
        {
          title: '工单状态',
          dataIndex: 'order_status',
          key: 'order_status',
          ellipsis: true,
          scopedSlots: { customRender: 'order_status' },
        },
        {
          title: '完成时间',
          dataIndex: 'time',
          key: 'time',
          ellipsis: true,
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '作业时间状态',
          dataIndex: 'order_exception',
          key: 'order_exception',
          ellipsis: true,
          scopedSlots: { customRender: 'order_exception' },
        },
        {
          title: '节点状态',
          dataIndex: 'process_node',
          key: 'process_node',
          ellipsis: true,
          scopedSlots: { customRender: 'process_node' },
        },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   key: 'operation',
        //   ellipsis: true,
        //   align:'center',
        //   scopedSlots: { customRender: 'operation' },
        //   width: 70
        // },
      ],
      matterDetailModalShowType:'',
      matterDetailModalDetailData: {},
      matterDetailModalVisible: false,
      repairDetailModalShowType:'',
      repairDetailModalDetailData: {},
      repairDetailModalVisible: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadBasemap']),
    modalTitle() {
      if (this.showType === 'repairAndMatter') {
        return '报事报修工单列表'
      } else if (this.showType == 'minOrder') {
        return '最短时间完成工单'
      } else if (this.showType === 'maxOrder') {
        return '最长时间完成工单'
      } else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        let dictionary = getCache('dictionary', true);
        if(dictionary) {
          this.bigInfluences = dictionary.bigInfluences;
        }
        this.getExclusiveRepairList();
        this.initDeptOptionsAll();
        this.initData();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initData(){
      this.getTableData();
    },
    getExclusiveRepairList() {
      getExclusiveRepair({monitorpointnum: this.detailData.monitorpointnum}).then(res => {
        if (res && res.returncode == '0') {
          this.exclusiveRepair = res.item
        }
      })
    },
    resetQueryForm() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.detailData,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      if(this.showType==='minOrder'){
        params.type="min"
        getMinOrMaxRepairsOrder(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else if(this.showType==='maxOrder'){
        params.type="max"
        getMinOrMaxRepairsOrder(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else{
        getRepairsOrderDetailListByCondition(params).then(res => {
          this.tableLoading = false;
          if (res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
    },
    showModal(type,record) {
      if(type==='detail'){
        if(record.order_type===0){
          this.matterDetailModalShowType='detail'
          this.matterDetailModalDetailData = record
          this.matterDetailModalVisible = true
        }else if(record.order_type===1){
          this.repairDetailModalShowType='detail'
          this.repairDetailModalDetailData = record
          this.repairDetailModalVisible = true
        }
      }
    },
    timeHandle(time){
      let returnTime;
      let day;
      let hour;
      let minutes;
      let seconds;
      if(time>86400) {
        day = Math.floor(time / 86400);
        returnTime=day + "天";
        if (time % 86400 > 3600) {
          hour = Math.floor(time % 86400 / 3600);
          returnTime+=hour + "时";
          if (time % 86400 % 3600 > 60) {
            minutes = Math.floor(time % 86400 % 3600 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400 % 3600 % 60);
            returnTime+=seconds + "秒";
          }
        } else {
          if (time % 86400 > 60) {
            minutes = Math.floor(time % 86400 / 60);
            returnTime+=minutes + "分";
            seconds = Math.floor(time % 86400 % 60);
            returnTime+=seconds + "秒";
          } else {
            seconds = Math.floor(time % 86400);
            returnTime+=seconds + "秒";
          }
        }
      }else if(time>=3600&&time<86400){
        let hour = Math.floor(time % 86400 / 3600);
        returnTime=hour + "时";
        if (time % 3600 > 60) {
          minutes = Math.floor(time % 3600 / 60);
          returnTime+=minutes + "分";
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        } else {
          seconds = Math.floor(time % 3600 % 60);
          returnTime+=seconds + "秒";
        }
      }else if(time>=60&&time<3600){
        minutes = Math.floor(time / 60);
        returnTime=minutes + "分";
        seconds = Math.floor(time % 60);
        returnTime+=seconds + "秒";
      }else if(time){
        returnTime=time + "秒";
      }else{
        returnTime = ""
      }
      return returnTime
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            if(record.order_type===0){
              this.matterDetailModalShowType='detail'
              this.matterDetailModalDetailData = record
              this.matterDetailModalVisible = true
            }else if(record.order_type===1){
              this.repairDetailModalShowType='detail'
              this.repairDetailModalDetailData = record
              this.repairDetailModalVisible = true
            }
          },
        },
      }
    },
  }
}
</script>
<style scoped>

</style>